import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useRecoilValue } from 'recoil';
import { Uuid } from '@egym/types';
import { useSnackbar } from '@egym/ui';
import {
  actualizeDemoApplication,
  getAdminApplication,
  getApplicationsStatusesCount,
  postApplication,
  postApplicationStatus,
  postDemoApplication,
  postDesignStatus,
  postSettingsStatus,
  putApplication,
  putDemoApplication,
  searchApplications,
} from '@api';
import { applicationsTableState } from '@globalState';
import {
  ActualizeDemoApplicationFormValues,
  ApplicationDto,
  ApplicationStatus,
  DemoApplicationFormValues,
} from '@types';
import { getApplicationResponseTErrorKey } from './helpers';

// https://tkdodo.eu/blog/effective-react-query-keys
export const applicationKeys = {
  all: ['adminApplications'] as const,
  lists: () => [...applicationKeys.all, 'list'] as const,
  list: filters => [...applicationKeys.lists(), { filters }] as const,
  details: () => [...applicationKeys.all, 'detail'] as const,
  detail: (id: Uuid = '') => [...applicationKeys.details(), String(id)] as const,
  statusesCount: () => [...applicationKeys.all, 'statusesCount'] as const,
};

const useApplications = (applicationUuid?: Uuid) => {
  const { openSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const tableQueryParams = useRecoilValue(applicationsTableState.selectors.queryParams);

  const onSaveError = (error, application: Partial<ApplicationDto>) => {
    const errorKey = getApplicationResponseTErrorKey(error.response?.status, error.response?.data.field);

    openSnackbar(t(errorKey, { value: application[error.response?.data.field] }), { severity: 'error' });
  };

  const applicationQuery = useQuery(
    applicationKeys.detail(applicationUuid),
    async () => getAdminApplication({ urlParams: { applicationUuid: applicationUuid || '' } }),
    {
      select: result => result.data,
      enabled: Boolean(applicationUuid),
      keepPreviousData: true,
      refetchOnMount: false,
    },
  );

  const searchApplicationsResponse = useQuery(
    applicationKeys.list(tableQueryParams),
    async () => searchApplications({ queryParams: tableQueryParams }),
    {
      select: result => result.data,
      onError: () => {
        openSnackbar(t('common.netError'), { severity: 'error' });
      },
      keepPreviousData: true,
      refetchOnMount: false,
    },
  );

  const applicationsStatusesCount = useQuery(
    applicationKeys.statusesCount(),
    async () => getApplicationsStatusesCount(),
    {
      select: ({ data }) => data,
      refetchOnMount: false,
      keepPreviousData: true,
    },
  );

  const createApplication = useMutation(async (data: ApplicationDto) => postApplication({ payload: data }), {
    onSuccess: async () => {
      await queryClient.invalidateQueries(applicationKeys.list(tableQueryParams));
      await queryClient.invalidateQueries(applicationKeys.statusesCount());
      openSnackbar(t('apps.labels.createSuccess'));
    },
    onError: (error, payload) => onSaveError(error, payload),
  });

  const createDemoApplication = useMutation(
    async (data: DemoApplicationFormValues) => postDemoApplication({ payload: data }),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(applicationKeys.statusesCount());
        await queryClient.invalidateQueries(applicationKeys.list(tableQueryParams));
        openSnackbar(t('apps.labels.createSuccess'));
      },
      onError: (error, payload) => onSaveError(error, payload),
    },
  );

  const updateApplication = useMutation(
    async (data: { payload: ApplicationDto; uuid: ApplicationDto['id'] }) =>
      putApplication({
        payload: data.payload,
        urlParams: { applicationUuid: data.uuid },
      }),
    {
      onSuccess: ({ data }) => {
        queryClient.setQueryData(applicationKeys.list(tableQueryParams), (prevData: any) => ({
          ...prevData,
          data: {
            ...prevData.data,
            content: prevData.data.content.map(item => (item.id === data.id ? data : item)),
          },
        }));
        queryClient.setQueryData(applicationKeys.detail(data.id), (prevData: any) => ({
          ...prevData,
          data,
        }));
        openSnackbar(t('apps.labels.updateSuccess'));
      },
      onError: (error, { payload }) => onSaveError(error, payload),
    },
  );

  const updateDemoApplication = useMutation(
    async (data: { payload: DemoApplicationFormValues; uuid: ApplicationDto['id'] }) =>
      putDemoApplication({
        payload: data.payload,
        urlParams: { applicationUuid: data.uuid },
      }),
    {
      onSuccess: ({ data }) => {
        queryClient.setQueryData(applicationKeys.list(tableQueryParams), (prevData: any) => ({
          ...prevData,
          data: {
            ...prevData.data,
            content: prevData.data.content.map(item => (item.id === data.id ? data : item)),
          },
        }));
        queryClient.setQueryData(applicationKeys.detail(data.id), (prevData: any) => ({
          ...prevData,
          data,
        }));
        openSnackbar(t('apps.labels.updateSuccess'));
      },
      onError: (error, { payload }) => onSaveError(error, payload),
    },
  );

  const upgradeDemoApplication = useMutation(
    async (data: { payload: ActualizeDemoApplicationFormValues; uuid: ApplicationDto['id'] }) =>
      actualizeDemoApplication({
        payload: data.payload,
        urlParams: { applicationUuid: data.uuid },
      }),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(applicationKeys.lists());
        await queryClient.invalidateQueries(applicationKeys.statusesCount());
        openSnackbar(t('apps.labels.updateSuccess'));
      },
      onError: (error, { payload }) => onSaveError(error, payload),
    },
  );

  const updateSettingsStatusMutation = useMutation(
    async (data: { uuid: ApplicationDto['id']; payload: { status: string } }) =>
      postSettingsStatus({
        payload: data.payload,
        urlParams: { applicationUuid: data.uuid },
      }),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(applicationKeys.list(tableQueryParams));
        openSnackbar(t('apps.labels.updateSuccess'));
      },
      onError: () => {
        openSnackbar(t('common.netError'), { severity: 'error' });
      },
    },
  );

  const updateDesignStatusMutation = useMutation(
    async (data: { uuid: ApplicationDto['id']; payload: { status: string } }) =>
      postDesignStatus({
        payload: data.payload,
        urlParams: { applicationUuid: data.uuid },
      }),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(applicationKeys.list(tableQueryParams));
        openSnackbar(t('apps.labels.updateSuccess'));
      },
      onError: () => {
        openSnackbar(t('common.netError'), { severity: 'error' });
      },
    },
  );

  const postApplicationStatusMutation = useMutation(
    async (data: { uuid: ApplicationDto['id']; payload: { status: ApplicationStatus; actionComment?: string } }) =>
      postApplicationStatus({ payload: data.payload, urlParams: { applicationUuid: data.uuid } }),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(applicationKeys.lists());
        await queryClient.invalidateQueries(applicationKeys.statusesCount());
        openSnackbar(t('apps.labels.updateSuccess'));
      },
      onError: () => {
        openSnackbar(t('common.netError'), { severity: 'error' });
      },
    },
  );

  return {
    searchApplicationsResponse,
    createApplication,
    updateApplication,
    createDemoApplication,
    updateDemoApplication,
    upgradeDemoApplication,
    updateSettingsStatusMutation,
    updateDesignStatusMutation,
    postApplicationStatusMutation,
    applicationsStatusesCount,
    applicationQuery,
  };
};

export default useApplications;
