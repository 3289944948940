import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const EditNoteIcon: React.FC<SvgIconProps> = ({ sx, htmlColor = '#878787', ...props }) => {
  return (
    <SvgIcon viewBox="0 0 16 16" {...props} sx={{ width: '16px', height: '16px', ...sx }}>
      <path
        d="M11.3346 8.6618V13.9998H2.0013V4.6665H7.33464V3.33317H0.667969V15.3332H12.668V8.6618H11.3346ZM13.8641 3.04554L13.7723 3.13669L12.8646 2.22902L12.9558 2.13786C13.2062 1.88687 13.6138 1.88752 13.8635 2.13722C14.1138 2.38757 14.1145 2.79519 13.8641 3.04554ZM7.93077 8.97881L6.70147 9.29977L7.02244 8.07113L11.957 3.13669L12.8646 4.04437L7.93077 8.97881ZM14.7718 1.22955C14.0201 0.478502 12.7992 0.479144 12.0481 1.22955L5.86825 7.40931L4.90535 11.0959L8.59196 10.133L14.7718 3.95321C15.5222 3.20217 15.5222 1.98059 14.7718 1.22955Z"
        fill={htmlColor}
        fillRule="evenodd"
        clipRule="evenodd"
      />
    </SvgIcon>
  );
};
