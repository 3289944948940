import { Uuid } from '@egym/types';

export type ApplicationSearchDto = {
  id: Uuid;
  name: string;
  status: ApplicationStatus;
  appPackage: ApplicationPackage;
  appType: ApplicationType;
  designStatus: ApplicationDesignStatus;
  designFormProgress?: FormProgress;
  settingsStatus: ApplicationSettingsStatus;
  settingsFormProgress?: FormProgress;
  actionComment?: string;
  notes?: string;
  createdAt: string;
  createdBy: string;
  updatedAt: string;
};

export type ApplicationDto = {
  id: Uuid;
  name: string;
  designStatus: ApplicationDesignStatus;
  settingsStatus: ApplicationSettingsStatus;
  status: ApplicationStatus;
  emails: string[];
  appPackage: ApplicationPackage;
  appType: ApplicationType;
  layout?: ApplicationLayout | '';
  createdAt: string;
  createdBy?: string;
  updatedAt: string;
  appStoreLink?: string;
  googlePlayLink?: string;
  actionComment?: string;
  notes?: string;
  demo: boolean;
  impManagerEmail?: string;
  csmManagersEmails: string[];
  additionalFeatures?: AdditionalFeature[];
  dashMigration: boolean;
};

export type ApplicationClientDto = {
  id: Uuid;
  name: string;
  tenantId: string;
  status: ApplicationStatus;
  designStatus: ApplicationDesignStatus;
  settingsStatus: ApplicationSettingsStatus;
  emails: string[];
  appPackage: ApplicationPackage;
  appType: ApplicationType;
  layout?: ApplicationLayout | '';
  shouldSkipUiDemo: boolean;
  createdAt: string;
  updatedAt: string;
  demo: boolean;
  impManagerEmail?: string;
  csmManagersEmails: string[];
  additionalFeatures?: AdditionalFeature[];
  dashMigration: boolean;
};

export type ApplicationFormValues = Pick<
  ApplicationDto,
  | 'id'
  | 'name'
  | 'emails'
  | 'appPackage'
  | 'appType'
  | 'layout'
  | 'appStoreLink'
  | 'googlePlayLink'
  | 'actionComment'
  | 'impManagerEmail'
  | 'csmManagersEmails'
  | 'dashMigration'
> & { additionalFeatures?: Partial<Record<AdditionalFeature, boolean>> };

export type DemoApplicationFormValues = Pick<
  ApplicationDto,
  'id' | 'name' | 'appPackage' | 'appType' | 'layout' | 'createdBy' | 'notes' | 'additionalFeatures'
>;

export type ActualizeDemoApplicationFormValues = Pick<
  ApplicationDto,
  'emails' | 'impManagerEmail' | 'csmManagersEmails'
>;

export enum ApplicationPackage {
  Basic = 'BASIC',
  Advanced = 'ADVANCED',
  Pro = 'PRO',
}

export enum ApplicationType {
  Standalone = 'STANDALONE',
  Container = 'CONTAINER',
}

export enum ApplicationStatus {
  InProgress = 'IN_PROGRESS',
  Released = 'RELEASED',
  Archived = 'ARCHIVED',
}

export enum ApplicationLayout {
  Workouts = 'WORKOUTS',
  Community = 'COMMUNITY',
  WorkoutsAndProgress = 'WORKOUTS_AND_PROGRESS',
  ClubAndClasses = 'CLUB_AND_CLASSES',
  ClubAndPerks = 'CLUB_AND_PERKS',
}

export enum AdditionalFeature {
  Genius = 'GENIUS',
}

export enum DemoApplication {
  Demo = 'DEMO',
  ArchivedDemo = 'ARCHIVED_DEMO',
}

export enum ApplicationDesignStatus {
  NOT_COMPLETED = 'NOT_COMPLETED',
  SUBMITTED = 'SUBMITTED',
  IN_REVIEW = 'IN_REVIEW',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
}

export enum ApplicationSettingsStatus {
  NOT_COMPLETED = 'NOT_COMPLETED',
  SUBMITTED = 'SUBMITTED',
  IN_REVIEW = 'IN_REVIEW',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  MIGRATION = 'MIGRATION',
}

export type FormProgress = {
  doneSteps?: number;
  allSteps?: number;
};

export type ApplicationStatusesCount = Record<ApplicationStatus | DemoApplication, number>;

export type ApplicationAssetArchiveProgress = {
  progress?: number;
  path?: string;
  url?: string;
  status: 'IN_PROGRESS' | 'COMPLETED';
};
