import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { TFunction } from 'i18next';
import { Uuid } from '@egym/types';
import { getDefaultAppLayoutTabs, transformDefaultTabForPreviewer } from '@helpers';
import { useClientApplication, useClientApplicationDesign } from '@hooks';
import { AppDesignFormValues } from '@types';

type Props = {
  appUuid: Uuid;
};

const useAppDesignLayoutAndTabsWithFallback = ({ appUuid }: Props) => {
  const { t } = useTranslation();
  const { appDesign } = useClientApplicationDesign(appUuid, false);
  const { applicationResponse, availableLayouts } = useClientApplication(appUuid);
  const {
    values: { layout, appLayoutTabs, locales },
  } = useFormikContext<AppDesignFormValues>();

  const layoutResult = useMemo(() => {
    return layout || appDesign.data?.layout || applicationResponse.data?.layout || availableLayouts[0];
  }, [appDesign.data?.layout, applicationResponse.data?.layout, availableLayouts, layout]);

  const appLayoutTabsResult = useMemo(() => {
    return appLayoutTabs.length
      ? appLayoutTabs
      : getDefaultAppLayoutTabs({
          appLayout: layoutResult,
          appPackage: applicationResponse.data?.appPackage,
          additionalFeatures: applicationResponse.data?.additionalFeatures,
        }).map((tab, index) => transformDefaultTabForPreviewer(tab, index, t as TFunction, locales));
  }, [
    appLayoutTabs,
    applicationResponse.data?.appPackage,
    applicationResponse.data?.additionalFeatures,
    layoutResult,
    t,
    locales,
  ]);

  return {
    layoutResult,
    appLayoutTabsResult,
  };
};

export default useAppDesignLayoutAndTabsWithFallback;
