import React, { forwardRef, ReactNode } from 'react';
import { Box } from '@mui/material';
import { Paper, Stack } from '@egym/ui';
import { AppLayoutFeatureComponentProps } from '@components';
import { EditNoteIcon } from '../../../../../../svg/EditNoteIcon';
import { IconWrapper } from '../../../Icons';
import PreviewContainer from '../../../PreviewContainer';
import PreviewFeatureTitle from '../../../PreviewFeatureTitle';

const PreviewGenius = forwardRef<HTMLDivElement, AppLayoutFeatureComponentProps>(
  ({ tFunction, palette, wrapperSx, previewerState, widgetProps }, ref) => {
    return (
      <PreviewContainer ref={ref} wrapperSx={wrapperSx}>
        <PreviewFeatureTitle
          tFunction={tFunction}
          palette={palette}
          title={
            widgetProps?.preferences?.title?.[previewerState.selectedLocale as string] || 'appPreviewer.labels.genius'
          }
        />
        <Paper
          sx={{
            textAlign: 'left',
            fontSize: '14px',
            color: 'rgb(33,33,33)',
            borderRadius: '12px',
            boxShadow: '0 4px 6px rgba(223, 223, 223, 0.7)',
          }}
        >
          <Stack spacing={0}>
            <Box
              component="div"
              sx={{
                width: '100%',
                height: '70px',
                borderRadius: '8px',
                overflow: 'hidden',
                position: 'relative',
                boxShadow: '0',
                marginTop: '10px',
                ...wrapperSx,
              }}
            >
              <Stack direction="column" sx={{ px: '20px' }}>
                <Stack direction="row" justifyContent="space-between" alignItems="top">
                  <Stack direction="column">
                    <span
                      style={{
                        fontSize: '14px',
                        lineHeight: '20px',
                        color: 'rgb(41, 41, 41)',
                        marginBottom: '2px',
                      }}
                    >
                      {tFunction('4-9 September') as ReactNode}
                    </span>
                    <span
                      style={{
                        fontSize: '22px',
                        lineHeight: '28px',
                        color: 'black',
                        fontWeight: 700,
                        marginBottom: '2px',
                      }}
                    >
                      {tFunction('Weekly Target') as ReactNode}
                    </span>
                    <span
                      style={{
                        fontSize: '10px',
                        lineHeight: '14px',
                        color: 'rgb(41, 41, 41)',
                        marginBottom: '2px',
                      }}
                    >
                      {tFunction('Muscle Building - Fitness Goal') as ReactNode}
                    </span>
                  </Stack>
                  <IconWrapper wrapperSx={{ pl: '2px' }} bgColor="rgba(245, 245, 245, 1)">
                    <EditNoteIcon htmlColor={palette.primary} />
                  </IconWrapper>
                </Stack>
              </Stack>
            </Box>
            <Box
              component="div"
              sx={{
                width: '100%',
                height: '180px',
                borderRadius: '8px',
                overflow: 'hidden',
                position: 'relative',
                boxShadow: '0',
                ...wrapperSx,
              }}
            >
              <Stack
                alignItems="center"
                justifyContent="center"
                sx={{
                  width: '100%',
                }}
              >
                <img
                  src="/images/previewerDefaultImages/features/genius_progress_rings.png"
                  style={{
                    width: '55%',
                    height: '55%',
                    objectFit: 'scale-down',
                  }}
                  alt="genius"
                />
              </Stack>
            </Box>
            <Box
              component="div"
              sx={{
                width: '100%',
                height: '70px',
                borderRadius: '8px',
                overflow: 'hidden',
                position: 'relative',
                boxShadow: '0',
                ...wrapperSx,
              }}
            >
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="top"
                sx={{
                  mx: '20px',
                }}
              >
                <Stack
                  sx={{
                    height: '100%',
                  }}
                >
                  <img src="/images/strength.png" alt="strength" style={{ width: '20px', height: '20px' }} />
                  <span
                    style={{
                      fontSize: '14px',
                      lineHeight: '19px',
                      color: 'black',
                      fontWeight: 600,
                      marginBottom: '2px',
                    }}
                  >
                    {tFunction('Strength') as ReactNode}
                  </span>
                  <Stack direction="row" alignItems="flex-end">
                    <span
                      style={{
                        fontSize: '18px',
                        lineHeight: '24px',
                        color: 'rgba(199, 83, 0, 1)',
                        fontWeight: 700,
                      }}
                    >
                      {tFunction('28/40') as ReactNode}
                    </span>
                    <span
                      style={{
                        fontSize: '12px',
                        lineHeight: '14px',
                        color: 'rgba(199, 83, 0, 1)',
                        marginLeft: '2px',
                        marginBottom: '4px',
                      }}
                    >
                      {tFunction('Sets') as ReactNode}
                    </span>
                  </Stack>
                </Stack>
                <Stack
                  sx={{
                    height: '100%',
                  }}
                >
                  <img src="/images/cardio.png" alt="cardio" style={{ width: '20px', height: '20px' }} />
                  <span
                    style={{
                      fontSize: '14px',
                      lineHeight: '19px',
                      color: 'black',
                      fontWeight: 600,
                      marginBottom: '2px',
                    }}
                  >
                    {tFunction('Cardio') as ReactNode}
                  </span>
                  <Stack direction="row" alignItems="flex-end">
                    <span
                      style={{
                        fontSize: '18px',
                        lineHeight: '24px',
                        color: 'rgba(219, 51, 80, 1)',
                        fontWeight: 700,
                      }}
                    >
                      {tFunction('21/60') as ReactNode}
                    </span>
                    <span
                      style={{
                        fontSize: '12px',
                        lineHeight: '14px',
                        color: 'rgba(219, 51, 80, 1)',
                        marginLeft: '2px',
                        marginBottom: '4px',
                      }}
                    >
                      {tFunction('Min.') as ReactNode}
                    </span>
                  </Stack>
                </Stack>
                <Stack
                  sx={{
                    height: '100%',
                  }}
                >
                  <img src="/images/flexibility.png" alt="flexibility" style={{ width: '20px', height: '20px' }} />
                  <span
                    style={{
                      fontSize: '14px',
                      lineHeight: '19px',
                      color: 'black',
                      fontWeight: 600,
                      marginBottom: '2px',
                    }}
                  >
                    {tFunction('Flexibility') as ReactNode}
                  </span>
                  <Stack direction="row" alignItems="flex-end">
                    <span
                      style={{
                        fontSize: '18px',
                        lineHeight: '24px',
                        color: 'rgba(25, 128, 142, 1)',
                        fontWeight: 700,
                      }}
                    >
                      {tFunction('8/15') as ReactNode}
                    </span>
                    <span
                      style={{
                        fontSize: '12px',
                        lineHeight: '14px',
                        color: 'rgba(25, 128, 142, 1)',
                        marginLeft: '2px',
                        marginBottom: '4px',
                      }}
                    >
                      {tFunction('Min.') as ReactNode}
                    </span>
                  </Stack>
                </Stack>
              </Stack>
            </Box>
            <Box
              component="div"
              sx={{
                width: '100%',
                height: '60px',
                borderRadius: '8px',
                overflow: 'hidden',
                position: 'relative',
                boxShadow: '0 4px 6px rgba(223, 223, 223, 0.7)',
                ...wrapperSx,
              }}
            >
              <Stack direction="column" sx={{ px: '20px' }}>
                <Paper
                  sx={{
                    height: '40px',
                    borderRadius: '20px',
                    background: palette.primary,
                    fontSize: '14px',
                    fontWeight: 700,
                    color: 'white',
                  }}
                  elevation={0}
                >
                  <Stack direction="column" justifyContent="center" alignItems="center" sx={{ height: '100%' }}>
                    <span style={{ lineHeight: '14px' }}>{tFunction('Start Day 1') as ReactNode}</span>
                  </Stack>
                </Paper>
              </Stack>
            </Box>
          </Stack>
        </Paper>
      </PreviewContainer>
    );
  },
);

export default PreviewGenius;
