import { FC } from 'react';
import { TFunction } from 'i18next';
import { mergeAll } from 'ramda';
import { AppLayoutFeatureEnum, AppPreviewerTab, compactToFullTabFeatureMap } from '@components';
import { getWidgetNameTKey } from '@helpers';
import { EditIconInverted } from '@svg';
import { AppDesignLayoutTabWidget } from '@types';
import {
  BasicWidgetPreferencesForm,
  FullTabWidgetPreferencesForm,
  GroupWebWidgetPreferencesForm,
  LocationFinderWidgetPreferencesForm,
  RewardsWidgetPreferencesForm,
  SimpleTitleCustomizedWidgetPreferencesForm,
  WebWidgetPreferencesForm,
} from '../../../../../WidgetPreferencesForm/components';

type EditableFeatureConfig = {
  subtitle: string;
  subtitleParams?: (t: TFunction) => Record<string, string>;
  formComponent: FC<any>;
  formComponentProps?: (widget: AppDesignLayoutTabWidget, appLayoutTabs: AppPreviewerTab[]) => Record<string, any>;
  editIconComponent?: FC;
  shouldShowResetButton: boolean;
};

export const editableFeatureConfigs: { [key in AppLayoutFeatureEnum]?: EditableFeatureConfig } = {
  [AppLayoutFeatureEnum.PreviewWebWidget]: {
    subtitle: 'appDesign.labels.widgets.updateWebWidget',
    subtitleParams: t => ({
      webWidgetType: t('appDesign.labels.widgets.singleWidget') as string,
    }),
    formComponent: WebWidgetPreferencesForm,
    formComponentProps: (widget, appLayoutTabs) => ({
      orientation: widget.groupId
        ? appLayoutTabs.find(tab => tab.id === widget.tabId)?.features?.find(it => it.props.id === widget.groupId)
            ?.props?.preferences?.orientation
        : undefined,
    }),
    shouldShowResetButton: false,
  },
  [AppLayoutFeatureEnum.PreviewGroupWebWidget]: {
    subtitle: 'appDesign.labels.widgets.updateWebWidget',
    subtitleParams: t => ({
      webWidgetType: t('appDesign.labels.widgets.groupForWidgets'),
    }),
    formComponent: GroupWebWidgetPreferencesForm,
    formComponentProps: widget => ({
      orientation: widget?.preferences?.orientation,
    }),
    editIconComponent: EditIconInverted,
    shouldShowResetButton: false,
  },
  ...mergeAll(
    [
      AppLayoutFeatureEnum.PreviewExtras,
      AppLayoutFeatureEnum.PreviewReferAFriend,
      AppLayoutFeatureEnum.PreviewRequestTrainer,
    ].map(feature => ({
      [feature]: {
        subtitle: getWidgetNameTKey(feature),
        formComponent: BasicWidgetPreferencesForm,
        shouldShowResetButton: true,
      },
    })),
  ),
  ...mergeAll(
    Object.entries(compactToFullTabFeatureMap).map(([compact, fullTab]) => ({
      [compact]: {
        subtitle: getWidgetNameTKey(compact as AppLayoutFeatureEnum),
        formComponent: FullTabWidgetPreferencesForm,
        formComponentProps: () => ({
          sizeOption: { Compact: compact, FullTab: fullTab },
        }),
        shouldShowResetButton: true,
      },
      [fullTab]: {
        subtitle: getWidgetNameTKey(compact as AppLayoutFeatureEnum),
        formComponent: FullTabWidgetPreferencesForm,
        formComponentProps: () => ({
          sizeOption: {
            Compact: compact,
            FullTab: fullTab,
          },
        }),
        shouldShowResetButton: true,
      },
    })),
  ),
  ...mergeAll(
    [
      AppLayoutFeatureEnum.PreviewActivityFeed,
      AppLayoutFeatureEnum.PreviewGoalCenter,
      AppLayoutFeatureEnum.PreviewDeals,
      AppLayoutFeatureEnum.PreviewChallenges,
      AppLayoutFeatureEnum.PreviewGeniusWidget,
    ].map(feature => ({
      [feature]: {
        subtitle: getWidgetNameTKey(feature),
        formComponent: SimpleTitleCustomizedWidgetPreferencesForm,
        shouldShowResetButton: true,
      },
    })),
  ),
  ...mergeAll(
    [AppLayoutFeatureEnum.PreviewLocationFinder].map(feature => ({
      [feature]: {
        subtitle: getWidgetNameTKey(feature),
        formComponent: LocationFinderWidgetPreferencesForm,
        shouldShowResetButton: true,
      },
    })),
  ),
  ...mergeAll(
    [AppLayoutFeatureEnum.PreviewRewards].map(feature => ({
      [feature]: {
        subtitle: getWidgetNameTKey(feature),
        formComponent: RewardsWidgetPreferencesForm,
        shouldShowResetButton: true,
      },
    })),
  ),
};
