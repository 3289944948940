import Color from 'color';
import { TFunction } from 'i18next';
import { map, mergeAll, uniq } from 'ramda';
import { v4 as uuidv4 } from 'uuid';
import { PartialBy } from '@egym/types';
import { toLocaleApiFormat, toLocaleFormat } from '@egym/utils';
import { AppLayoutFeatureEnum, appLayoutFeatureMap, AppPreviewerTab } from '@components';
import {
  COLOR_CONTRAST_THRESHOLD,
  defaultAppLayoutsConfig,
  validationErrors,
  widgetsCategoriesGroups,
} from '@constants';
import {
  AdditionalFeature,
  AppDesignCustomColors,
  AppDesignFormValues,
  AppDesignLayoutTab,
  AppDesignLayoutTabType,
  AppDesignLayoutTabWidget,
  AppDesignMenuItemEnum,
  ApplicationDesignDto,
  ApplicationLayout,
  ApplicationPackage,
  ColorThemeEnum,
  DefaultAppDesignLayoutTab,
  DesignValidationError,
} from '@types';
import { getGStoreResource } from './common';

export const getAvailableAppLayouts = (appPackage: ApplicationPackage): ApplicationLayout[] => {
  const layoutValues = Object.values(ApplicationLayout);
  switch (appPackage) {
    case ApplicationPackage.Basic:
      return [ApplicationLayout.Workouts];
    case ApplicationPackage.Advanced:
      return layoutValues.filter(
        layout => ![ApplicationLayout.Workouts, ApplicationLayout.ClubAndPerks].includes(layout),
      );
    case ApplicationPackage.Pro:
      return layoutValues.filter(layout => layout !== ApplicationLayout.Workouts);
    default:
      return layoutValues;
  }
};

export const getWidgetsCategoriesGroupsWithAvailableWidgets = (appPackage: ApplicationPackage) => {
  let availableWidgets = Object.keys(defaultAppLayoutsConfig[appPackage].layouts).reduce<AppLayoutFeatureEnum[]>(
    (acc, layout) => {
      const layoutFeatures = defaultAppLayoutsConfig[appPackage].layouts[layout].reduce<AppLayoutFeatureEnum[]>(
        (layoutFeaturesAcc, tab) => [...layoutFeaturesAcc, ...tab.defaultFeatures],
        [],
      );
      const extraPackageFeatures = defaultAppLayoutsConfig[appPackage].extraFeatures;
      return [...acc, ...layoutFeatures, ...extraPackageFeatures];
    },
    [],
  );

  if (appPackage !== ApplicationPackage.Basic) {
    availableWidgets = [
      ...availableWidgets,
      AppLayoutFeatureEnum.PreviewWebWidget,
      AppLayoutFeatureEnum.PreviewGroupWebWidget,
    ];
  }

  const uniqAvailableWidgets = uniq(availableWidgets);

  return Object.keys(widgetsCategoriesGroups).reduce(
    (acc, category) => ({
      ...acc,
      [category]: widgetsCategoriesGroups[category].filter(feature => uniqAvailableWidgets.includes(feature)),
    }),
    {},
  );
};

export const getDefaultPreviewerFeature = (widgetProps: PartialBy<AppDesignLayoutTabWidget, 'tabId'>) => {
  return {
    props: {
      disabled: false,
      preferences: undefined,
      ...widgetProps,
      id: widgetProps.id || null,
    },
    component: appLayoutFeatureMap[widgetProps.name],
  };
};

export const transformCustomTabForPreviewer = (
  tab: AppDesignLayoutTab,
  customFeatures: AppDesignLayoutTabWidget[],
  t: TFunction,
  appLocales: string[],
  defaultTab?: DefaultAppDesignLayoutTab,
): AppPreviewerTab => {
  const featuresResult: AppDesignLayoutTabWidget[] = customFeatures?.length
    ? customFeatures.sort((widgetA, widgetB) => widgetA.order - widgetB.order)
    : [];

  return {
    id: tab.id,
    order: tab.order,
    type: tab.type,
    defaultTitleKey: defaultTab?.defaultTitle,
    name: defaultTab?.defaultTitle
      ? mergeAll(
          map(
            (locale: string) => ({
              [locale]: tab.localizedName[locale],
            }),
            map(locale => toLocaleApiFormat(locale), appLocales),
          ),
        )
      : tab.localizedName,
    icon: tab?.icon?.link ? getGStoreResource(tab.icon.link) : defaultTab?.defaultIcon?.path || '',
    iconPredefinedId: tab?.icon?.id || defaultTab?.defaultIcon?.id || undefined,
    features: featuresResult.map(widgetConfig => {
      return {
        props: widgetConfig,
        component: appLayoutFeatureMap[widgetConfig.name],
      };
    }),
  };
};

export const transformDefaultTabForPreviewer = (
  tab: DefaultAppDesignLayoutTab,
  order: number,
  t: TFunction,
  appLocales: string[],
): AppPreviewerTab => {
  const tabId = Object.values(AppDesignLayoutTabType).findIndex(type => type === tab.type);

  return {
    id: tabId,
    order,
    type: tab.type,
    name: mergeAll(
      map(
        locale => ({
          [toLocaleApiFormat(locale)]: t(tab.defaultTitle, { lng: toLocaleFormat(locale) }),
        }),
        appLocales,
      ),
    ),
    icon: tab.defaultIcon.path,
    iconPredefinedId: tab.defaultIcon.id,
    isDemoTab: tab.isDemoTab,
    features: tab.defaultFeatures.map((feature, index) =>
      getDefaultPreviewerFeature({ id: uuidv4(), name: feature, tabId, order: index }),
    ),
    isDefaultTab: true,
  };
};

export const transformTabForApi = ({
  id,
  type,
  name,
  order,
  icon,
  iconPredefinedId,
}: AppPreviewerTab): AppDesignLayoutTab => {
  return {
    id,
    type,
    localizedName: name,
    order,
    icon: {
      id: iconPredefinedId,
      link: icon,
    },
  };
};

export const prepareCustomLayoutTabs = (
  customAppLayoutTabs: AppDesignLayoutTab[],
  defaultAppLayoutTabs: DefaultAppDesignLayoutTab[],
  customWidgets: AppDesignLayoutTabWidget[],
  t: TFunction,
  appLocales: string[],
) => {
  return customAppLayoutTabs
    .sort((tabA, tabB) => tabA.order - tabB.order)
    .map(tab => {
      const correspondingDefaultTab = defaultAppLayoutTabs.find(defaultTab => defaultTab.type === tab.type);

      const tabWidgets = customWidgets.filter(widget => widget.tabId === tab.id);

      return transformCustomTabForPreviewer(tab, tabWidgets, t, appLocales, correspondingDefaultTab);
    })
    .filter(tab => {
      // filter out Video tab with no features inside.
      // todo: Remove this after WT-564
      return !(tab.type === AppDesignLayoutTabType.Video && !tab.features.length);
    });
};

export type GetDefaultAppLayoutTabsProps = {
  appLayout?: ApplicationLayout;
  appPackage?: ApplicationPackage;
  additionalFeatures?: AdditionalFeature[];
};

export const getDefaultAppLayoutTabs = ({
  appPackage,
  appLayout,
  additionalFeatures,
}: GetDefaultAppLayoutTabsProps) => {
  if (!appPackage || !appLayout || !additionalFeatures) {
    return [];
  }

  const layoutTabs = defaultAppLayoutsConfig[appPackage].layouts[appLayout];

  if (additionalFeatures?.includes(AdditionalFeature.Genius)) {
    const layoutTabsWithAdditionalFeatures: DefaultAppDesignLayoutTab[] = JSON.parse(JSON.stringify(layoutTabs));

    layoutTabsWithAdditionalFeatures
      .filter(it => [AppDesignLayoutTabType.Workouts, AppDesignLayoutTabType.Wellness].includes(it.type))
      .forEach(it => it.defaultFeatures.unshift(AppLayoutFeatureEnum.PreviewGeniusWidget));
    return layoutTabsWithAdditionalFeatures;
  }
  return layoutTabs;
};

export const getMainTabHeaderIconResult = (predefinedId, icon) => (predefinedId !== 31 ? icon : null);

export const getWidgetNameTKey = (
  widgetName: AppLayoutFeatureEnum,
  widgetPreferences?: Record<string, any> | null,
  selectedPreviewerLocale?: string,
) => {
  const customTitle = widgetPreferences?.title?.[selectedPreviewerLocale || 'en_US'] || widgetPreferences?.title?.en_US;
  switch (widgetName) {
    case AppLayoutFeatureEnum.PreviewNotificationCenter:
      return 'appDesign.labels.widgetNames.notifications';
    // case AppLayoutFeatureEnum.PreviewVirtualClasses:
    // case AppLayoutFeatureEnum.PreviewVirtualClassesOnTheTab:
    //   return 'appDesign.labels.widgetNames.virtualClasses';
    case AppLayoutFeatureEnum.PreviewActivityLevel:
    case AppLayoutFeatureEnum.PreviewActivityLevelOnTheTab:
      return 'appDesign.labels.widgetNames.activityLevel';
    case AppLayoutFeatureEnum.PreviewFindAClass:
    case AppLayoutFeatureEnum.PreviewFindAClassOnTheTab:
      return 'appDesign.labels.widgetNames.classBooking';
    case AppLayoutFeatureEnum.PreviewBioAge:
    case AppLayoutFeatureEnum.PreviewBioAgeOnTheTab:
      return 'appDesign.labels.widgetNames.analysis';
    case AppLayoutFeatureEnum.PreviewRanking:
    case AppLayoutFeatureEnum.PreviewRankingOnTheTab:
      return 'appDesign.labels.widgetNames.ranking';
    case AppLayoutFeatureEnum.PreviewTrainingPlans:
      return 'appDesign.labels.widgetNames.trainingPlans';
    case AppLayoutFeatureEnum.PreviewActivityHistory:
      return 'appDesign.labels.widgetNames.latestActivity';
    // case AppLayoutFeatureEnum.PreviewWorkoutTracking:
    //   return 'appDesign.labels.widgetNames.trackWorkout';
    case AppLayoutFeatureEnum.PreviewChallenges:
      return 'appDesign.labels.widgetNames.challenges';
    case AppLayoutFeatureEnum.PreviewLocationFinder:
      return 'appDesign.labels.widgetNames.homeLocation';
    case AppLayoutFeatureEnum.PreviewActivityFeed:
      return 'appDesign.labels.widgetNames.activityFeed';
    case AppLayoutFeatureEnum.PreviewRewards:
      return 'appDesign.labels.widgetNames.rewards';
    case AppLayoutFeatureEnum.PreviewGoalCenter:
      return 'appDesign.labels.widgetNames.goals';
    case AppLayoutFeatureEnum.PreviewDeals:
      return 'appDesign.labels.widgetNames.deals';
    case AppLayoutFeatureEnum.PreviewExtras:
      return 'appDesign.labels.widgetNames.news';
    case AppLayoutFeatureEnum.PreviewConnectedApps:
      return 'appDesign.labels.widgetNames.connectedApps';
    case AppLayoutFeatureEnum.PreviewReferAFriend:
      return 'appDesign.labels.widgetNames.referAFriend';
    case AppLayoutFeatureEnum.PreviewRequestTrainer:
      return 'appDesign.labels.widgetNames.requestTrainer';
    case AppLayoutFeatureEnum.PreviewGuestPass:
      return 'appDesign.labels.widgetNames.guestPass';
    case AppLayoutFeatureEnum.PreviewWebWidget:
      return customTitle || 'appDesign.labels.widgetNames.webWidget_one';
    case AppLayoutFeatureEnum.PreviewGroupWebWidget:
      return customTitle || 'appDesign.labels.widgetNames.groupWebWidget';
    // case AppLayoutFeatureEnum.PreviewExploreSchedule:
    //   return 'appDesign.labels.widgetNames.exploreSchedule';
    case AppLayoutFeatureEnum.PreviewGeniusWidget:
      return 'appDesign.labels.widgetNames.genius';
    default:
      return 'appDesign.labels.widgetNames.webWidget_one';
  }
};

export const getTranslatedTabName =
  ({ name }: { name: AppPreviewerTab['name'] }) =>
  (selectedLocale: string): string => {
    return name[selectedLocale] || name.en_US;
  };

export const getNameHasDefaultValues = (name: AppPreviewerTab['name'], t: TFunction) =>
  Object.entries(name).some(
    ([locale, localizedName]) =>
      !localizedName || localizedName === t('common.buttons.newTab', { lng: toLocaleFormat(locale) }),
  );

export const validateColorOnHighContrast = (color: string): boolean => {
  const contrast = Color(String(color)).contrast(Color('white'));
  return contrast < COLOR_CONTRAST_THRESHOLD;
};

export const validateCustomColors = (
  colorTheme: ColorThemeEnum,
  customColors: AppDesignCustomColors,
): { primary: boolean; secondary: boolean } | null => {
  if (colorTheme === ColorThemeEnum.CUSTOM && customColors?.primary && customColors?.secondary) {
    return {
      primary: validateColorOnHighContrast(customColors.primary),
      secondary: validateColorOnHighContrast(customColors.secondary),
    };
  }
  return null;
};

export const validateDashboard3Background = (
  customColors: AppDesignCustomColors,
): { primary: boolean; secondary: boolean } | null => {
  if (!customColors || !customColors.dashboard3BackgroundPrimary || !customColors.dashboard3BackgroundSecondary)
    return null;

  return {
    primary: validateColorOnHighContrast(customColors.dashboard3BackgroundPrimary),
    secondary: validateColorOnHighContrast(customColors.dashboard3BackgroundSecondary),
  };
};

const validateAppDesignTabWidget = (
  tab: AppPreviewerTab,
  widget: AppDesignLayoutTabWidget,
): DesignValidationError[] => {
  const result: DesignValidationError[] = [];

  if (
    widget.id &&
    widget.name === AppLayoutFeatureEnum.PreviewGroupWebWidget &&
    (widget.preferences?.innerWidgets?.length ?? 0) < 2
  ) {
    result.push(validationErrors.widgetGroupIsNotConfigured({ tabId: tab.id, widgetId: widget.id }));
  }
  return result;
};

const validateAppDesignTab = (tab: AppPreviewerTab, t: TFunction): DesignValidationError[] => {
  const result: DesignValidationError[] = [];
  if (!tab.name || getNameHasDefaultValues(tab.name, t)) {
    result.push(validationErrors.defaultTabName({ tabId: tab.id }));
  }
  if (!tab.features?.length) {
    result.push(validationErrors.tabIsEmpty({ tabId: tab.id }));
  }
  tab.features?.forEach(feature => {
    result.push(...validateAppDesignTabWidget(tab, feature.props));
  });

  return result;
};

export const validateAppDesignTabsState = (tabs: AppPreviewerTab[], t: TFunction): DesignValidationError[] => {
  return tabs.flatMap(tab => validateAppDesignTab(tab, t));
};

export const validateAppDesign = ({ appLayoutTabs }: AppDesignFormValues, t: TFunction): DesignValidationError[] => {
  return validateAppDesignTabsState(appLayoutTabs, t);
};

// TODO: WT-751
export const appDesignProgressAllSteps = Object.values(AppDesignMenuItemEnum).filter(
  it => ![AppDesignMenuItemEnum.TabsOrder, AppDesignMenuItemEnum.TabCustomization].includes(it),
).length;

export const calculateAppDesignProgress = (data: Partial<ApplicationDesignDto>): number => {
  const { layout, locales, colorTheme, customColors, appIcon, signIn, mainTabHeader, mainTabBrandingTabVisited } = data;
  const progressConditions = [
    {
      condition: Boolean(layout),
    },
    {
      condition: Boolean(locales?.length),
    },
    {
      condition: Boolean(colorTheme && colorTheme !== ColorThemeEnum.CUSTOM),
    },
    {
      condition: Boolean(
        colorTheme && colorTheme === ColorThemeEnum.CUSTOM && customColors?.primary && customColors.secondary,
      ),
    },
    {
      condition:
        Boolean(mainTabBrandingTabVisited) ||
        Boolean(mainTabHeader?.iconLink) ||
        Boolean(customColors?.dashboard3BackgroundPrimary),
    },
    {
      condition: Boolean(appIcon?.link) && Boolean(appIcon?.backgroundColor),
    },
    {
      condition:
        Boolean(typeof signIn?.predefinedImageId === 'number' || signIn?.customImageId) &&
        Boolean(signIn?.backgroundImageOverlayColor) &&
        Boolean(signIn?.logoLink || signIn?.logoDisabled),
    },
  ];

  return progressConditions.filter(it => it.condition).length;
};
