import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { FormControlLabel, Switch, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import { FieldProps } from 'formik';
import { PartialBy } from '@egym/types';
import { ConditionalRender } from '@egym/ui';

type SwitchFormFieldProps = {
  label: string;
  formControlSx?: SxProps;
  fieldSx?: SxProps;
  isViewMode?: boolean;
  viewModeSx?: SxProps;
  disabled?: boolean;
};

type Props = PartialBy<FieldProps, 'form' | 'meta'> & SwitchFormFieldProps;

const SwitchFormField: React.FC<Props> = ({
  formControlSx,
  fieldSx,
  label,
  field,
  isViewMode,
  viewModeSx,
  disabled,
}) => {
  const { t } = useTranslation();
  return (
    <FormControlLabel
      sx={formControlSx}
      control={
        <ConditionalRender condition={isViewMode}>
          <Typography sx={viewModeSx}>
            {field.value ? (t('common.buttons.yes') as ReactNode) : (t('common.buttons.no') as ReactNode)}
          </Typography>
          <Switch
            name={field.name}
            checked={field.value || false}
            disabled={isViewMode || disabled}
            onChange={e => field.onChange({ ...e, target: { name: e.target.name, value: e.target.checked } })}
            sx={fieldSx}
          />
        </ConditionalRender>
      }
      disabled={isViewMode || disabled}
      labelPlacement={isViewMode ? 'top' : 'end'}
      label={isViewMode ? <Typography variant="caption">{t(label) as ReactNode}</Typography> : (t(label) as ReactNode)}
    />
  );
};

export default SwitchFormField;
